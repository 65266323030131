<template>
  <div class="my-fans flex">
    <div class="top-fixed">
      <div class="top-header">
        <div class="holder">
          <div class="header flex">
            <div class="header-left">
              <p class="num">{{ fansInfo.aCount || 0 }}</p>
              <div class="num-text flex align-center">
                <p>我的粉丝</p>
                <img src="@/assets/img/user/myFans/help.png" alt="" />
              </div>
            </div>
            <div class="header-right flex">
              <div class="r-i left" @click.stop="showFun">
                <img src="@/assets/img/user/myFans/add.png" alt="" />
                <p class="r-i-p">我的推荐人</p>
              </div>
              <div class="r-i right" @click="showPhone = true">
                <img src="@/assets/img/user/myFans/serve.png" alt="" />
                <p class="r-i-p">客服</p>
              </div>
            </div>
          </div>
          <div class="main">
            <div class="m-box flex flex-around">
              <div class="num one">
                <p class="title">{{ fansInfo.eCount || 0 }}</p>
                <div class="name flex flex-center align-center">
                  <!-- <p>专属粉丝</p> -->
                  <p>金标达人</p>
                  <img src="@/assets/img/user/myFans/help1.png" alt="" />
                </div>
              </div>
              <div class="num two">
                <p class="title">{{ fansInfo.nCount || 0 }}</p>
                <div class="name flex flex-center align-center">
                  <!-- <p>普通粉丝</p> -->
                  <p>蓝标达人</p>
                  <img src="@/assets/img/user/myFans/help1.png" alt="" />
                </div>
              </div>
              <!-- <div class="num three">
                <p class="title">{{ fansInfo.pCount || 0 }}</p>
                <div class="name">
                  <p>潜在粉丝</p>
                </div>
              </div> -->
            </div>
            <div class="t-box">
              <div class="i-box flex flex-between">
                <div class="i-box-item" @click="hrefFun('/teamReport')">
                  <img src="@/assets/img/user/myFans/chart.png" alt="" />
                  <p>达人报表</p>
                </div>
                <div class="i-box-item" @click="hrefFun('/inviteFriend')">
                  <img src="@/assets/img/user/myFans/friend.png" alt="" />
                  <p>邀请好友</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="f-box flex">
        <div class="tab-f-header">
          <van-tabs v-model:active="active" @change="tabChange">
            <van-tab title="金标达人"></van-tab>
            <van-tab title="蓝标达人"></van-tab>
          </van-tabs>
        </div>
        <div class="content flex">
          <div v-if="active == 0" class="hold-tab left-tab">
            <div class="s-box flex align-center">
              <img src="@/assets/img/user/myFans/search.png" alt="" />
              <input v-model="name" type="text" placeholder="搜索微信昵称" />
            </div>
            <div class="r-box flex align-center" @click="timeFun">
              <p>加入时间</p>
              <img
                v-show="sortMethod == 1"
                src="@/assets/img/user/myFans/top.png"
              />
              <img
                v-show="sortMethod != 1"
                src="@/assets/img/user/myFans/bottom.png"
              />
            </div>
          </div>
          <div v-else class="hold-tab right-tab">
            <div class="r-box flex align-center" @click="timeFun">
              <p>加入时间</p>
              <img
                v-show="sortMethod == 1"
                src="@/assets/img/user/myFans/top.png"
              />
              <img
                v-show="sortMethod != 1"
                src="@/assets/img/user/myFans/bottom.png"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      :class="[
        'list-all',
        { 'all-left': active == 0 },
        { 'all-right': active == 1 },
      ]"
    >
      <van-list
        v-model:loading="loading"
        :immediate-check="false"
        :finished="finished"
        @load="onLoad"
      >
        <div v-if="list.length > 0" class="load-list">
          <div v-for="(item, index) in list" :key="index" class="list flex">
            <img :src="item.picture" alt="" />
            <div class="l-center">
              <div class="t-t flex align-center">
                <p class="name">{{ item.name }}</p>
                <p v-if="item.memberType === 1" class="text">种草达人</p>
              </div>
              <p class="time">{{ item.gmtCreate }}</p>
            </div>
            <div class="l-right">
              <!-- <p>发起私聊</p> -->
            </div>
          </div>
          <p v-if="finished" class="bottom">—— 没有更多了 ——</p>
        </div>
        <div v-else-if="list.length == 0 && finished" class="no-list">
          <img src="@/assets/img/user/myFans/no-data.png" alt="" />
          <p>暂无粉丝列表</p>
        </div>
      </van-list>
    </div>
    <van-popup
      v-model:show="isShow"
      position="center"
      :style="{ width: '100%' }"
      :close-on-click-overlay="false"
      class="alert-popup"
    >
      <div class="alert-box">
        <img
          src="@/assets/img/user/myFans/close.png"
          class="close"
          @click="isShow = false"
        />
        <div class="code flex align-center flex-center">
          <p></p>
          我的推荐人
          <p></p>
        </div>
        <div class="text flex">
          <p class="left">编号：</p>
          <p class="right">{{ fansInfo.code || "" }}</p>
        </div>
        <!-- <div class="text flex">
          <p class="left">微信号：</p>
          <p class="right">{{ fansInfo.code || '' }}</p>
        </div> -->
        <div class="text last-text flex">
          <p class="left">微信昵称：</p>
          <p class="right">{{ fansInfo.parentName || "" }}</p>
        </div>
        <p class="btn-b" @click="copyFun(fansInfo.code)">复制编号</p>
      </div>
    </van-popup>
    <!-- 客服1 -->
    <phone-popup></phone-popup>
  </div>
</template>
<script src="./index.js"></script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
<style lang="scss">
.my-fans {
  .top-fixed {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
  }
  .no-list {
    text-align: center;
    img {
      width: 98px;
      height: 98px;
      margin: 60px auto 25px;
    }
    p {
      height: 22px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #cccccc;
      line-height: 22px;
      text-align: center;
    }
  }
  .f-box {
    flex-direction: column;
    width: 100%;
    height: calc(100% - 300px);
    background: #ffffff;
    .tab-f-header {
      width: 100%;
      height: 63px;
    }
    .van-tabs {
      width: 100%;
    }
    .van-tabs__wrap {
      border-bottom: solid 1px #ededed !important;
    }
    .van-tabs__line {
      width: 25px !important;
      height: 2px !important;
      background: linear-gradient(270deg, #ff3014 0%, #e14813 100%) !important;
      bottom: 16px !important;
    }
    .van-tab {
      height: 25px !important;
      font-size: 15px !important;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666 !important;
      line-height: 25px !important;
      padding: 0 !important;
      span {
        margin-bottom: 5px;
      }
    }
    .van-tab:nth-child(1) {
      width: 188px !important;
      margin: 15px 0 0 5px !important;
    }
    .van-tab:nth-child(2) {
      width: 177px !important;
      margin: 15px 0 0 0 !important;
    }
    .van-tab--active {
      color: #333333 !important;
    }
    .content {
      width: 100%;
      height: calc(100% - 63px);
      flex-direction: column;
      .hold-tab {
        width: 100%;
        display: flex;
        padding-bottom: 5px;
        flex-shrink: 0;
        .s-box {
          width: 260px;
          height: 32px;
          background: #f5f5f5;
          -webkit-border-radius: 16px;
          border-radius: 16px;
          margin: 0 0 0 15px;
          img {
            width: 15px;
            margin: 0 8px 0 12px;
            flex-shrink: 0;
          }
          input {
            background: none;
            height: 18px;
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #333333;
            line-height: 18px;
            flex-grow: 1;
          }
          input::-webkit-input-placeholder {
            color: #cccccc;
          }
        }
        .r-box {
          flex-grow: 1;
          p {
            height: 18px;
            font-size: 13px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #999999;
            line-height: 18px;
            flex-grow: 1;
            text-align: right;
          }
          img {
            width: 6px;
            height: 10px;
            margin: 0 15px 0 7px;
          }
        }
      }
      .right-tab {
        .r-box {
          flex-grow: unset !important;
          p {
            margin-left: 15px;
          }
        }
      }
    }
  }
  .list-all {
    width: 100%;
    .load-list {
      .bottom {
        height: 50px;
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #cccccc;
        line-height: 50px;
        text-align: center;
      }
    }
  }
  .all-left {
    height: 100%;
    padding-top: 400px;
  }
  .all-right {
    height: 100%;
    padding-top: 386px;
  }
  .list {
    width: 345px;
    height: 75px;
    border-bottom: solid 1px #ededed;
    margin: 0 auto;
    img {
      width: 48px;
      height: 48px;
      flex-shrink: 0;
      margin: 15px 10px 0 0;
      border-radius: 50%;
      overflow: hidden;
    }
    .l-center {
      text-align: left;
      .t-t {
        height: 20px;
        margin-top: 17px;
        .name {
          height: 20px;
          font-size: 15px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 20px;
        }
        .text {
          width: 60px;
          height: 16px;
          background: #ffedd8;
          -webkit-border-radius: 2px;
          border-radius: 2px;
          font-size: 11px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #d59046;
          margin-left: 5px;
          line-height: 16px;
          text-align: center;
        }
      }
      .time {
        height: 20px;
        font-size: 11px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 20px;
        margin-top: 5px;
      }
    }
    .l-right {
      flex-grow: 1;
      position: relative;
      p {
        width: 72px;
        height: 28px;
        border-radius: 14px;
        border: 1px solid #666666;
        text-align: center;
        line-height: 28px;
        position: absolute;
        top: 25px;
        right: 5px;
      }
    }
  }
  .alert-popup {
    width: 100% !important;
    transform: unset !important;
    background: none !important;
    left: 0;
    top: 0 !important;
    background: unset !important;
    .alert-box {
      width: 280px;
      background: #ffffff;
      -webkit-border-radius: 8px;
      border-radius: 8px;
      position: relative;
      margin: 181px auto 0;
      padding: 20px 0;
      .btn-b {
        width: 230px;
        height: 36px;
        background: #e62129;
        border-radius: 18px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        text-align: center;
        line-height: 36px;
        border: 1px solid #e62129;
        margin: 0 auto;
      }
      .close {
        width: 24px;
        height: 24px;
        position: absolute;
        top: -34px;
        right: 1px;
      }
      .code {
        height: 21px;
        font-size: 15px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 21px;
        text-align: center;
        margin-bottom: 25px;
        p {
          background: #e62129;
          width: 8px;
          height: 1.5px;
        }
        p:nth-child(1) {
          margin-right: 5px;
        }
        p:nth-child(2) {
          margin-left: 5px;
        }
      }
      .text {
        margin-bottom: 10px;
        .left {
          width: 90px;
          height: 21px;
          font-size: 15px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          line-height: 21px;
          text-align: left;
          margin-left: 40px;
          flex-shrink: 0;
        }
        .right {
          font-size: 15px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 21px;
          margin-right: 40px;
        }
      }
      .last-text {
        margin-bottom: 30px;
      }
    }
  }
}
</style>