import { myFansInfo, myExclusiveFansList, myPotentialFansList } from "@/utils/user.js"
import phonePopup from '@/components/phone.vue';
import { provide, ref } from 'vue';
import useClipboard from "vue-clipboard3"
export default {
    name: "MyFans",
    setup() {
        let showPhone = ref(false);
        provide('showphone', showPhone);
        return {
            showPhone
        }
    },
    data() {
        return {
            pageNo: 1,
            name: "",
            pageSize: 10,
            isShow: false,
            active: 0,
            loading: false,
            finished: false,
            list: [],
            sortMethod: 1,
            fansInfo: {},
        }
    },
    components: {
        phonePopup
    },
    created() {
        this.getFansInfo()
        this.getFansList()
    },
    methods: {
        async copyFun(val) {
            try {
                const { toClipboard } = useClipboard()
                await toClipboard(val)
                this.$toast.success("已复制编号")
            } catch (e) {
                console.error(e)
            }
        },
        getFansInfo() {
            // 获取粉丝统计数据
            const data = {
                memberId: this.$cookies.get('memberId')
            }
            myFansInfo(data).then(res => {
              this.fansInfo = res && res.data ? res.data : {}
            })
        },
        tabChange() {
            this.sortMethod = 1
            this.pageNo = 1
            this.list = []
            this.finished = false
            this.loading = true
            this.getFansList()
        },
        timeFun() {
            this.sortMethod = this.sortMethod == 1 ? 2 : 1
            this.pageNo = 1
            this.list = []
            this.finished = false
            this.loading = true
            this.getFansList()
        },
        getFansList() {
            const data = {
                memberId: this.$cookies.get('memberId'),
                pageNo: this.pageNo,
                pageSize: this.pageSize,
                sortMethod: this.sortMethod
            }
            if (this.active === 0 && this.name) {
                data.name = this.name
            }
            // 专属粉丝列表myExclusiveFansList，潜在粉丝列表myPotentialFansList
            const apiFun = this.active === 0 ? myExclusiveFansList : myPotentialFansList
            apiFun(data).then(res => {
                this.loading = false;
                if (res && res.code == "0") {
                    const arr = res && res.data && res.data.memberList ? res.data.memberList :  []
                    this.list = this.list.concat(arr)
                    if (arr.length < this.pageSize) {
                        this.finished = true;
                    } else {
                        this.pageNo++;
                    }
                } else {
                    this.finished = true;
                }
            })
        },
        onLoad() {
            this.loading = true
            // 异步更新数据
            setTimeout(() => {
                this.getFansList();
            }, 300);
        },
        showFun() {
            if (!this.fansInfo.parentName) {
                this.$toast('暂无推荐人')
            } else {
                this.isShow = true
            }
        },
        hrefFun(val) {
            this.$router.push(val)
        }
    }
}